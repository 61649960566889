<template>
  <div class="modal fade" id="proxysmartUpdateModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered w-[700px]">
          <div class="modal-content">
              <div class="modal-header">
                  <h1 class="modal-title fs-5">Update ProxySmart</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="float-left w-full px-2 py-2">
                    <div class=" mb-2">
                        OS Spoofing
                    </div>
                    <v-select v-model="selectedOSType" :items="types" density="compact" item-title="name" rounded="0"
                    variant="outlined" style="padding-top: 0px;padding-bottom: 0px;">
                        <template v-slot:selection="{ item }"  >
                            <v-list-item :title="item.raw.name" density style="padding: 0;">
                            <template v-slot:prepend>
                                {{item.name}}
                            </template>
                            </v-list-item>
                        </template>
                        <template v-slot:item="{ props, item }">
                            <v-list-item v-bind="props" :title="item.raw.name"   density="compact">
                            <template v-slot:prepend>
                                {{ item.name }}
                            </template>
                            </v-list-item>
                        </template>
                    </v-select>
                </div>
                  <div class="float-left w-full px-2 py-2">
                      Data Limits
                      <div class="flex items-center justify-between flex-wrap">
                          <v-radio-group v-model="quotaType" inline class="pr-5 modal-item">
                              <v-radio label="Unlimited" value="unlimited"></v-radio>
                              <v-radio label="Limited (MB)" value="limited"></v-radio>
                          </v-radio-group>
                          <div class="flex w-1/2 space-x-2 pl-2 modal-item" v-if="quotaType === 'limited'">
                              <v-select v-model="quotaDirType" :items="quotaDirTypes" variant="outlined" rounded="0"
                                  density="compact" class="w-5/12 modal-item-small"></v-select>
                              <v-text-field v-model="quotaAmount" variant="outlined" density="compact" rounded="0"
                                  type="number" style="" class="w-[100px] modal-item-small"></v-text-field>
                              <v-select v-model="quotaTimeUnit" :items="timeUnitTypes" variant="outlined" rounded="0"
                                  density="compact" class="w-1/3 modal-item-small"></v-select>
                          </div>
                      </div>
                  </div>
                  <!-- <div class="float-left w-1/2 px-2 py-2">
                      Restriction Whitelist
                      <v-textarea v-model="whitelist" variant="outlined" density="compact" rows="2" no-resize
                          rounded="0"
                          placeholder="Please fill website list separate by comma (empty for ignore). Ex: *facebook.com,*.youtube.com"
                          aria-details="none" :height="40"></v-textarea>
                  </div> -->
                  <div class="float-left w-1/2 px-2 py-2 modal-item">
                      Restriction Blacklist
                      <v-textarea v-model="blacklist" variant="outlined" density="compact" rows="2" no-resize
                          rounded="0"
                          placeholder="Please fill website list separate by comma (empty for ignore). Ex: facebook.com, youtube.com'"></v-textarea>
                  </div>
                  <div class="float-left w-full px-2 py-2 ">
                      Proxy Download Speed Limits
                      <div class="flex items-center justify-between flex-wrap">
                          <v-radio-group v-model="bandLimitInType" inline>
                              <v-radio label="Unlimited" value="unlimited"></v-radio>
                              <v-radio label="Limited (mbps)" value="limited"></v-radio>
                          </v-radio-group>
                          <div class="flex w-1/2" v-if="bandLimitInType === 'limited'">
                              <v-text-field v-model="bandLimitInAmount" variant="outlined" density="compact"
                                  rounded="0" type="number" style=""></v-text-field>
                          </div>
                      </div>
                  </div>
                  <div class="float-left w-full px-2 py-2">
                      Proxy Upload Speed Limits
                      <div class="flex items-center justify-between flex-wrap">
                          <v-radio-group v-model="bandLimitOutType" inline>
                              <v-radio label="Unlimited" value="unlimited"></v-radio>
                              <v-radio label="Limited (mbps)" value="limited"></v-radio>
                          </v-radio-group>
                          <div class="flex w-1/2" v-if="bandLimitOutType === 'limited'">
                              <v-text-field v-model="bandLimitOutAmount" variant="outlined" density="compact"
                                  rounded="0" type="number" style=""></v-text-field>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                  <button type="button" :disabled="buttonDisableFlag" class="btn btn-primary"
                      @click="updateProxy">Update</button>
              </div>
          </div>
      </div>
  </div>
</template>

<script setup>
import { ref, watchEffect, defineEmits, defineProps } from 'vue';
import { useAdminProxyStore, useAlertStore } from "@/stores";

const emit = defineEmits(['inInput', 'submit']);
const adminProxyStore = useAdminProxyStore();
const props = defineProps({
  item: {
      type: Object, // Assuming user object has a name property
      required: true
  },
});


const buttonDisableFlag = ref(false);
const blacklist = ref('');
const quotaAmount = ref(0);
const quotaTimeUnit = ref('Daily');
const bandLimitInAmount = ref(0);
const bandLimitOutAmount = ref(0);
const quotaType = ref('unlimited');

const types = ref([
    {value: '', name: '--No Spoofing--'}, 
    {value: 'android:1', name: 'android:1, p0f compliand but slow'},
    {value: 'android:3', name: 'android:3, real Android, almost like Linux'},
    {value: 'android:4', name: 'android:4, real Android 14'},
    {value: 'macosx:3', name: 'macosx:3'},
    {value: 'macosx:4', name: 'macosx:4, real MacOSX 12.6 / iPhone 13 Pro Max'},
    {value: 'macosx:5', name: 'macosx:5, real MacOSX Ventura 13.5.1'},
    {value: 'ios:1', name: 'ios:1, p0f compliant'},
    {value: 'ios:2', name: 'ios:2, real lphone'},
    {value: 'ios:3', name: 'ios:3, real IPhone 12 max pro'},
    {value: 'windows:1', name: 'windows:1, real Windnows 10 64bit Server or Tiny'},
    {value: 'windows:4', name: 'windows:4, real Windows 10 and 11 64bit Desktop'}
])
const selectedOSType = ref({value: '', name: '--No Spoofing--'})
const quotaDirTypes = ref([ 'Download', 'Upload', 'Download+Upload'])
const quotaDirType = ref('Download')
const bandLimitInType = ref('unlimited');
const bandLimitOutType = ref('unlimited');
const timeUnitTypes = ref(['Daily', 'Monthly', 'Lifetime']);



watchEffect(() => {
  selectedOSType.value = props?.item ? types.value.find(t => t.value === props?.item.osType) || { value: '', name: '--No Spoofing--' } : '';
  blacklist.value = props?.item ? props.item.blacklist : '';
  quotaAmount.value = props?.item ? props.item.quotaAmount : 0;
  quotaTimeUnit.value = props?.item ? props.item.quotaTimeUnit : 'Daily';
  bandLimitInAmount.value = props?.item ? props.item.bandLimitInAmount : 0;
  bandLimitOutAmount.value = props?.item ? props.item.bandLimitOutAmount : 0;
  quotaType.value = props?.item ? props.item.quotaType : 'unlimited';
  quotaDirType.value = props?.item ? props.item.quotaDirType : 'Download';
  bandLimitInType.value = props?.item ? props.item.bandLimitInType : 'unlimited';
  bandLimitOutType.value = props?.item ? props.item.bandLimitOutType : 'unlimited';
})

function updateProxy() {
  const proxyRequest = {
      blacklist: blacklist.value,
      quotaAmount: parseInt(quotaAmount.value),
      quotaTimeUnit: quotaTimeUnit.value,
      bandLimitInAmount: parseInt(bandLimitInAmount.value),
      bandLimitOutAmount: parseInt(bandLimitOutAmount.value),
      quotaType: quotaType.value === "unlimited" ? 0 : 1,
      quotaDirType: quotaDirType.value,
      bandLimitInType: bandLimitInType.value === "unlimited" ? 0 : 1,
      bandLimitOutType: bandLimitOutType.value === "unlimited" ? 0 : 1,
      osType: selectedOSType.value?.name ? selectedOSType.value?.value : selectedOSType.value,
  };
  buttonDisableFlag.value = true;
  adminProxyStore.updateProxy(props.item.uuid, props.item.modem_type, props.item.modem_id, proxyRequest).then(() => {
      buttonDisableFlag.value = false;
      emit('close');
  });
}


watchEffect(() => {
  // while(availableModemList.value.httpList.include(http_port.value)) http_port.value++;
  // while(availableModemList.value.socketList.include(sock_port.value)) sock_port.value++;
  quotaAmount.value = Math.max(0, quotaAmount.value);
  bandLimitInAmount.value = Math.max(0, bandLimitInAmount.value);
  bandLimitOutAmount.value = Math.max(0, bandLimitOutAmount.value);
}, []);

</script>