<template>
    <div class="modal fade" id="xproxyAddModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered  w-[700px] ">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Add New Proxy (XProxy)</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body flex flex-wrap ">
                    <div class="float-left w-1/3 px-2 py-2 modal-item">
                        Position From:
                        <v-select v-model="selectedFromPosition" :items="positionList" variant="outlined" rounded="0"
                            density="compact"></v-select>
                    </div>
                    <div class="float-left w-1/3 px-2 py-2 modal-item">
                        Position To:
                        <v-select v-model="selectedToPosition"
                            :items="positionList?.filter(item => item >= selectedFromPosition)" variant="outlined"
                            rounded="0" density="compact"></v-select>
                    </div>
                    <div class="float-left w-1/3 px-2 py-2 modal-item">
                        Number of Ports
                        <v-text-field v-model="portNumber" variant="outlined" density="compact" type="number"
                            rounded="0" style=""></v-text-field>
                    </div>
                    <div class="float-left w-1/2 px-2 py-2 h-20 modal-item">
                        <span @click="authType = 'username'" style="cursor: pointer;" class="flex items-center py-2">
                            <div class="w-5 mr-4">
                                <input :checked="authType === 'username'" class="form-check-input shadow-none"
                                    type="checkbox" />
                            </div>
                            <span class="text-sm">Authentication User:</span>
                        </span>

                        <v-text-field v-model="userAuthentication" variant="outlined" density="compact" rounded="0"
                            :disabled="authType !== 'username'">
                            <template v-slot:append-inner>
                                <v-icon icon="mdi-refresh" @click="generateRandomCredentials"></v-icon>
                            </template>
                        </v-text-field>
                    </div>
                    <div class="float-left w-1/2 px-2 py-2 h-20 modal-item">
                        <span @click="authType = 'ip'" style="cursor: pointer;" class="flex items-center py-2">
                            <div class="w-5 mr-4">
                                <input :checked="authType === 'ip'" class="form-check-input shadow-none"
                                    type="checkbox" />

                            </div>
                            <span class="text-sm">Authentication IPs:</span>
                        </span>

                        <v-text-field v-model="ipAuthentication" variant="outlined" density="compact" rounded="0"
                            :disabled="authType !== 'ip'"></v-text-field>
                    </div>
                    <div class="float-left w-1/3 px-2 py-2 modal-item">
                        HTTP port start
                        <v-text-field v-model="http_start" variant="outlined" density="compact" type="number"
                            rounded="0" style=""></v-text-field>
                    </div>
                    <div class="float-left w-1/3 px-2 py-2 modal-item">
                        Sock port start
                        <v-text-field v-model="sock_start" variant="outlined" density="compact" rounded="0"
                            type="number"></v-text-field>
                    </div>
                    <div class="float-left w-1/3 px-2 py-2 modal-item">
                        Max connection
                        <v-text-field v-model="max_conn" variant="outlined" density="compact" type="number" rounded="0"
                            style=""></v-text-field>
                    </div>
                    <!-- <div class="float-left w-1/2 px-2 py-2">
                        Restriction Whitelist
                        <v-textarea v-model="whitelist" variant="outlined" density="compact" rows="2" no-resize
                            rounded="0"
                            placeholder="Please fill website list separate by comma (empty for ignore). Ex: *facebook.com,*.youtube.com"
                            aria-details="none" :height="40"></v-textarea>
                    </div> -->
                    <div class="float-left w-1/2 px-2 py-2 modal-item">
                        Restriction Blacklist
                        <v-textarea v-model="blacklist" variant="outlined" density="compact" rows="2" no-resize
                            rounded="0"
                            placeholder="Please fill website list separate by comma (empty for ignore). Ex: facebook.com, youtube.com'"></v-textarea>
                    </div>
                    <div class="px-2 py-2 float-left w-full">
                        Upload quota
                        <div class=" flex items-center justify-between flex-wrap">
                            <v-radio-group v-model="uploadLimitType" inline class="pr-5 modal-item">
                                <v-radio label="Unlimited" value="unlimited"></v-radio>
                                <v-radio label="Limited (MB)" value="limited"></v-radio>
                            </v-radio-group>
                            <div class="flex w-1/2 modal-item" v-if="uploadLimitType === 'limited'">
                                <v-text-field v-model="uploadLimitAmount" variant="outlined" density="compact"
                                    rounded="0" type="number" style="" class=" mr-5"></v-text-field>
                                <v-select v-model="uploadLimitTimeUnit" :items="timeUnitTypes" variant="outlined"
                                    rounded="0" density="compact" class="w-1/2"></v-select>
                            </div>
                        </div>
                    </div>
                    <!-- Download quota -->
                    <div class="px-2 py-2 float-left w-full">
                        Download quota
                        <div class="flex items-center justify-between flex-wrap">
                            <v-radio-group v-model="downloadLimitType" inline class="pr-5 modal-item">
                                <v-radio label="Unlimited" value="unlimited"></v-radio>
                                <v-radio label="Limited (MB)" value="limited"></v-radio>
                            </v-radio-group>
                            <div class="flex w-1/2 modal-item" v-if="downloadLimitType === 'limited'">
                                <v-text-field v-model="downloadLimitAmount" variant="outlined" density="compact"
                                    rounded="0" inputmode="numeric" type="number" style="" class="mr-5"></v-text-field>
                                <v-select v-model="downloadLimitTimeUnit" :items="timeUnitTypes" variant="outlined"
                                    rounded="0" density="compact" class="w-1/2"></v-select>
                            </div>
                        </div>
                    </div>

                    <!-- Up/Down quota -->
                    <div class="px-2 py-2 float-left w-full">
                        Up/Down quota
                        <div class="flex items-center justify-between flex-wrap">
                            <v-radio-group v-model="upDownLimitType" inline class="pr-5 modal-item">
                                <v-radio label="Unlimited" value="unlimited"></v-radio>
                                <v-radio label="Limited (MB)" value="limited"></v-radio>
                            </v-radio-group>
                            <div class="flex w-1/2 modal-item" v-if="upDownLimitType === 'limited'">
                                <v-text-field v-model="upDownLimitAmount" variant="outlined" density="compact"
                                    rounded="0" type="number" style="" class="mr-5"></v-text-field>
                                <v-select v-model="upDownLimitTimeUnit" :items="timeUnitTypes" variant="outlined"
                                    rounded="0" density="compact" class="w-1/2"></v-select>
                            </div>
                        </div>
                    </div>

                    <!-- Bandwidth limit -->
                    <div class="px-2 py-2 float-left w-full">
                        Bandwidth limit
                        <div class="flex items-center justify-between flex-wrap">
                            <v-radio-group v-model="bandLimitType" inline class="pr-5 modal-item">
                                <v-radio label="Unlimited" value="unlimited"></v-radio>
                                <v-radio label="Limited (MB)" value="limited"></v-radio>
                            </v-radio-group>
                            <div class="flex w-1/2 modal-item" v-if="bandLimitType === 'limited'">
                                <v-text-field v-model="bandLimitAmount" variant="outlined" density="compact" rounded="0"
                                    type="number" style=""></v-text-field>
                            </div>
                        </div>
                    </div>
                    <div class="px-2 py-2 float-left w-full">
                        Custom DNS:
                        <div class="flex items-center justify-between mt-[8px]">
                            <div class="flex w-full">
                                <v-text-field v-model="customDNS" variant="outlined" density="compact" rounded="0"
                                     style=""></v-text-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" :disabled="buttonDisableFlag" class="btn btn-primary"
                        @click="createNewProxy">Create</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import { ref, watchEffect, defineEmits, defineProps } from 'vue';
import { useAdminProxyStore, useAlertStore } from "@/stores"

const emit = defineEmits(['inInput', 'submit'])

const adminProxyStore = useAdminProxyStore()
const props = defineProps({
    serverName: {
        type: Object, // Assuming user object has a name property
        required: true
    },
});
const selectedServerName = ref(props.serverName)
const positionList = ref([])
watchEffect(async () => {
    if (selectedServerName.value&&selectedServerName.value!=='All') {
        positionList.value = await adminProxyStore.loadPositionList(selectedServerName.value)
    }
}, [selectedServerName.value])

watchEffect(()=>{
    selectedServerName.value = props.serverName
}, [props.serverName])
const buttonDisableFlag = ref(false)

const selectedFromPosition = ref(1);
const selectedToPosition = ref(1);
const portNumber = ref(1);
const userAuthentication = ref('');
const ipAuthentication = ref('');
const http_start = ref(40001);
const sock_start = ref(41001);
const max_conn = ref(1000);
const whitelist = ref('');
const blacklist = ref('');
const uploadLimitAmount = ref(0);
const uploadLimitTimeUnit = ref('DAILY');
const downloadLimitAmount = ref(0);
const downloadLimitTimeUnit = ref('DAILY');
const upDownLimitAmount = ref(0);
const upDownLimitTimeUnit = ref('DAILY');
const bandLimitAmount = ref(0);
const customDNS = ref('')
const uploadLimitType = ref('unlimited');
const downloadLimitType = ref('unlimited');
const upDownLimitType = ref('unlimited');
const bandLimitType = ref('unlimited');
const timeUnitTypes = ref(['DAILY', 'WEEKLY', 'MONTHLY'])
function createNewProxy() {
    let temp_auth_user = userAuthentication.value
    let temp_auth_ips = ipAuthentication.value
    switch (authType.value) {
        case "username":
            temp_auth_ips = null
            break
        case "ip":
            temp_auth_user = null
            break
    }
    if(authType.value === "username"){
        const userpass = temp_auth_user.split(':');
        if(userpass[0].length < 4 || userpass[1].length < 4) {
            const alertStore = useAlertStore()
            alertStore.error("Please lengthen username and password to 4 characters or more");
            return
        }
    }
    const proxyRequest = {
        selectedFromPosition: parseInt(selectedFromPosition.value),
        selectedToPosition: parseInt(selectedToPosition.value),
        portNumber: parseInt(portNumber.value),
        userAuthentication: temp_auth_user,
        ipAuthentication: temp_auth_ips,
        http_start: parseInt(http_start.value),
        sock_start: parseInt(sock_start.value),
        max_conn: parseInt(max_conn.value),
        whitelist: whitelist.value,
        blacklist: blacklist.value,
        uploadLimitAmount: parseInt(uploadLimitAmount.value),
        uploadLimitTimeUnit: uploadLimitTimeUnit.value,
        downloadLimitAmount: parseInt(downloadLimitAmount.value),
        downloadLimitTimeUnit: downloadLimitTimeUnit.value,
        upDownLimitAmount: parseInt(upDownLimitAmount.value),
        upDownLimitTimeUnit: upDownLimitTimeUnit.value,
        bandLimitAmount: parseInt(bandLimitAmount.value),
        customDNS: customDNS.value,
        uploadLimitType: uploadLimitType.value === "unlimited" ? 0 : 1,
        downloadLimitType: downloadLimitType.value === "unlimited" ? 0 : 1,
        upDownLimitType: upDownLimitType.value === "unlimited" ? 0 : 1,
        bandLimitType: bandLimitType.value === "unlimited" ? 0 : 1,
    };
    buttonDisableFlag.value = true
    adminProxyStore.createNewProxy(selectedServerName.value, proxyRequest).then(() => {
        adminProxyStore.loadProxies(selectedServerName.value)
        buttonDisableFlag.value=false
        emit('close');
    })


}
function generateRandomCredentials() {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let username = '';
    let password = '';
    for (let i = 0; i < 7; i++) {
        username += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    for (let i = 0; i < 7; i++) {
        password += Math.floor(Math.random() * 10); // Generates a random digit between 0 and 9
    }
    userAuthentication.value = `u${username}:p${password}`;
}

watchEffect(() => {
    portNumber.value = Math.max(1, portNumber.value)
    http_start.value = Math.max(0, http_start.value)
    sock_start.value = Math.max(0, sock_start.value)
    max_conn.value = Math.max(0, max_conn.value)
    uploadLimitAmount.value = Math.max(0, uploadLimitAmount.value)
    downloadLimitAmount.value = Math.max(0, downloadLimitAmount.value)
    upDownLimitAmount.value = Math.max(0, upDownLimitAmount.value)
    bandLimitAmount.value = Math.max(0, bandLimitAmount.value)
}, [portNumber.value,])
const authType = ref('username');   

generateRandomCredentials();
</script>